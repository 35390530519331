@mixin pseudo-element-image($src, $width, $height) {
    background-image: url($src);
    width: $width;
    height: $height;
    background-size: contain;
    background-repeat: no-repeat;
}

@mixin children-inherit-font {
    a,
    p,
    span {
        font: inherit;
        color: inherit;
    }
}

@mixin basic-transition {
  transition: all 0.15s;
}

// Fonts

// *** DO NOT REMOVE THIS ***
//$$ mixins-hook-start $$

// typography
@mixin h1 {
	font-size: 70px;
	line-height: 88px;
	@include first-font-bold;
}
@mixin h1-tablet {
	@include breakpoint (medium down) {
		font-size: 70px;
		line-height: 88px;
		@include first-font-bold;
	}
}
@mixin h1-mobile {
	@include breakpoint (small down) {
		font-size: 70px;
		line-height: 88px;
		@include first-font-bold;
	}
}
@mixin h2 {
	font-size: 35px;
	line-height: 40px;
	@include first-font-regular;
}
@mixin h2-tablet {
	@include breakpoint (medium down) {
		font-size: 35px;
		line-height: 40px;
		@include first-font-regular;
	}
}
@mixin h2-mobile {
	@include breakpoint (small down) {
		font-size: 35px;
		line-height: 40px;
		@include first-font-regular;
	}
}
@mixin h3 {
	font-size: 25px;
	line-height: 32px;
	@include first-font-regular;
}
@mixin h3-tablet {
	@include breakpoint (medium down) {
		font-size: 25px;
		line-height: 32px;
		@include first-font-regular;
	}
}
@mixin h3-mobile {
	@include breakpoint (small down) {
		font-size: 25px;
		line-height: 32px;
		@include first-font-regular;
	}
}
@mixin h4 {
	font-size: 18px;
	line-height: 24px;
	@include first-font-regular;
}
@mixin h4-tablet {
	@include breakpoint (medium down) {
		font-size: 18px;
		line-height: 24px;
		@include first-font-regular;
	}
}
@mixin h4-mobile {
	@include breakpoint (small down) {
		font-size: 18px;
		line-height: 24px;
		@include first-font-regular;
	}
}
@mixin body-1 {
	font-size: 16px;
	line-height: 24px;
}
@mixin body-1-tablet {
	@include breakpoint (medium down) {
		font-size: 16px;
		line-height: 24px;
	}
}
@mixin body-1-mobile {
	@include breakpoint (small down) {
		font-size: 16px;
		line-height: 24px;
	}
}
@mixin body-2 {
	font-size: 14px;
	line-height: 24px;
}
@mixin body-2-tablet {
	@include breakpoint (medium down) {
		font-size: 14px;
		line-height: 24px;
	}
}
@mixin body-2-mobile {
	@include breakpoint (small down) {
		font-size: 14px;
		line-height: 24px;
	}
}
@mixin body-3 {
	font-size: 12px;
	line-height: 16px;
}
@mixin body-3-tablet {
	@include breakpoint (medium down) {
		font-size: 12px;
		line-height: 16px;
	}
}
@mixin body-3-mobile {
	@include breakpoint (small down) {
		font-size: 12px;
		line-height: 16px;
	}
}
@mixin upper {
	text-transform: uppercase;
}
@mixin upper-tablet {
	@include breakpoint (medium down) {
		text-transform: uppercase;
	}
}
@mixin upper-mobile {
	@include breakpoint (small down) {
		text-transform: uppercase;
	}
}
@mixin lower {
	text-transform: lowercase;
}
@mixin lower-tablet {
	@include breakpoint (medium down) {
		text-transform: lowercase;
	}
}
@mixin lower-mobile {
	@include breakpoint (small down) {
		text-transform: lowercase;
	}
}
@mixin capitalize {
	text-transform: capitalize;
}
@mixin capitalize-tablet {
	@include breakpoint (medium down) {
		text-transform: capitalize;
	}
}
@mixin capitalize-mobile {
	@include breakpoint (small down) {
		text-transform: capitalize;
	}
}
@mixin initial {
	text-transform: initial;
}
@mixin initial-tablet {
	@include breakpoint (medium down) {
		text-transform: initial;
	}
}
@mixin initial-mobile {
	@include breakpoint (small down) {
		text-transform: initial;
	}
}

// borders
@mixin border {
	border: 1px solid $line-color;
}
@mixin border-tablet {
	@include breakpoint (medium down) {
		border: 1px solid $line-color;
	}
}
@mixin border-mobile {
	@include breakpoint (small down) {
		border: 1px solid $line-color;
	}
}
@mixin border-no-top {
	border-top: none;
}
@mixin border-no-top-tablet {
	@include breakpoint (medium down) {
		border-top: none;
	}
}
@mixin border-no-top-mobile {
	@include breakpoint (small down) {
		border-top: none;
	}
}
@mixin border-no-bottom {
	border-bottom: none;
}
@mixin border-no-bottom-tablet {
	@include breakpoint (medium down) {
		border-bottom: none;
	}
}
@mixin border-no-bottom-mobile {
	@include breakpoint (small down) {
		border-bottom: none;
	}
}
@mixin border-no-left {
	border-left: none;
}
@mixin border-no-left-tablet {
	@include breakpoint (medium down) {
		border-left: none;
	}
}
@mixin border-no-left-mobile {
	@include breakpoint (small down) {
		border-left: none;
	}
}
@mixin border-no-right {
	border-right: none;
}
@mixin border-no-right-tablet {
	@include breakpoint (medium down) {
		border-right: none;
	}
}
@mixin border-no-right-mobile {
	@include breakpoint (small down) {
		border-right: none;
	}
}
@mixin border-right {
	border-right: 1px solid $line-color;
}
@mixin border-right-tablet {
	@include breakpoint (medium down) {
		border-right: 1px solid $line-color;
	}
}
@mixin border-right-mobile {
	@include breakpoint (small down) {
		border-right: 1px solid $line-color;
	}
}
@mixin border-top {
	border-top: 1px solid $line-color;
}
@mixin border-top-tablet {
	@include breakpoint (medium down) {
		border-top: 1px solid $line-color;
	}
}
@mixin border-top-mobile {
	@include breakpoint (small down) {
		border-top: 1px solid $line-color;
	}
}
@mixin border-bottom {
	border-bottom: 1px solid $line-color;
}
@mixin border-bottom-tablet {
	@include breakpoint (medium down) {
		border-bottom: 1px solid $line-color;
	}
}
@mixin border-bottom-mobile {
	@include breakpoint (small down) {
		border-bottom: 1px solid $line-color;
	}
}
@mixin border-left {
	border-left: 1px solid $line-color;
}
@mixin border-left-tablet {
	@include breakpoint (medium down) {
		border-left: 1px solid $line-color;
	}
}
@mixin border-left-mobile {
	@include breakpoint (small down) {
		border-left: 1px solid $line-color;
	}
}
@mixin border-black {
	border-color: $black;
}
@mixin border-black-tablet {
	@include breakpoint (medium down) {
		border-color: $black;
	}
}
@mixin border-black-mobile {
	@include breakpoint (small down) {
		border-color: $black;
	}
}
@mixin border-grey-3 {
	border-color: $grey-3;
}
@mixin border-grey-3-tablet {
	@include breakpoint (medium down) {
		border-color: $grey-3;
	}
}
@mixin border-grey-3-mobile {
	@include breakpoint (small down) {
		border-color: $grey-3;
	}
}
@mixin border-grey-2 {
	border-color: $grey-2;
}
@mixin border-grey-2-tablet {
	@include breakpoint (medium down) {
		border-color: $grey-2;
	}
}
@mixin border-grey-2-mobile {
	@include breakpoint (small down) {
		border-color: $grey-2;
	}
}
@mixin border-grey-1 {
	border-color: $grey-1;
}
@mixin border-grey-1-tablet {
	@include breakpoint (medium down) {
		border-color: $grey-1;
	}
}
@mixin border-grey-1-mobile {
	@include breakpoint (small down) {
		border-color: $grey-1;
	}
}
@mixin border-light-grey {
	border-color: $light-grey;
}
@mixin border-light-grey-tablet {
	@include breakpoint (medium down) {
		border-color: $light-grey;
	}
}
@mixin border-light-grey-mobile {
	@include breakpoint (small down) {
		border-color: $light-grey;
	}
}
@mixin border-white {
	border-color: $white;
}
@mixin border-white-tablet {
	@include breakpoint (medium down) {
		border-color: $white;
	}
}
@mixin border-white-mobile {
	@include breakpoint (small down) {
		border-color: $white;
	}
}
@mixin border-first-color {
	border-color: $first-color;
}
@mixin border-first-color-tablet {
	@include breakpoint (medium down) {
		border-color: $first-color;
	}
}
@mixin border-first-color-mobile {
	@include breakpoint (small down) {
		border-color: $first-color;
	}
}
@mixin border-second-color {
	border-color: $second-color;
}
@mixin border-second-color-tablet {
	@include breakpoint (medium down) {
		border-color: $second-color;
	}
}
@mixin border-second-color-mobile {
	@include breakpoint (small down) {
		border-color: $second-color;
	}
}
@mixin border-third-color {
	border-color: $third-color;
}
@mixin border-third-color-tablet {
	@include breakpoint (medium down) {
		border-color: $third-color;
	}
}
@mixin border-third-color-mobile {
	@include breakpoint (small down) {
		border-color: $third-color;
	}
}
@mixin radius-small {
	border-radius: $radius-small;
}
@mixin radius-small-tablet {
	@include breakpoint (medium down) {
		border-radius: $radius-small;
	}
}
@mixin radius-small-mobile {
	@include breakpoint (small down) {
		border-radius: $radius-small;
	}
}
@mixin radius-medium {
	border-radius: $radius-medium;
}
@mixin radius-medium-tablet {
	@include breakpoint (medium down) {
		border-radius: $radius-medium;
	}
}
@mixin radius-medium-mobile {
	@include breakpoint (small down) {
		border-radius: $radius-medium;
	}
}
@mixin radius-large {
	border-radius: $radius-large;
}
@mixin radius-large-tablet {
	@include breakpoint (medium down) {
		border-radius: $radius-large;
	}
}
@mixin radius-large-mobile {
	@include breakpoint (small down) {
		border-radius: $radius-large;
	}
}

// text-alignment
@mixin txt-center {
	text-align: center;
}
@mixin txt-center-tablet {
	@include breakpoint (medium down) {
		text-align: center;
	}
}
@mixin txt-center-mobile {
	@include breakpoint (small down) {
		text-align: center;
	}
}
@mixin txt-left {
	text-align: left;
}
@mixin txt-left-tablet {
	@include breakpoint (medium down) {
		text-align: left;
	}
}
@mixin txt-left-mobile {
	@include breakpoint (small down) {
		text-align: left;
	}
}
@mixin txt-right {
	text-align: right;
}
@mixin txt-right-tablet {
	@include breakpoint (medium down) {
		text-align: right;
	}
}
@mixin txt-right-mobile {
	@include breakpoint (small down) {
		text-align: right;
	}
}
@mixin txt-ellipsize {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 400px;
}
@mixin txt-ellipsize-tablet {
	@include breakpoint (medium down) {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		max-width: 400px;
	}
}
@mixin txt-ellipsize-mobile {
	@include breakpoint (small down) {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		max-width: 400px;
	}
}

// text-decorations
@mixin underline {
	text-decoration: underline;
}
@mixin underline-tablet {
	@include breakpoint (medium down) {
		text-decoration: underline;
	}
}
@mixin underline-mobile {
	@include breakpoint (small down) {
		text-decoration: underline;
	}
}
@mixin line-through {
	text-decoration: line-through;
}
@mixin line-through-tablet {
	@include breakpoint (medium down) {
		text-decoration: line-through;
	}
}
@mixin line-through-mobile {
	@include breakpoint (small down) {
		text-decoration: line-through;
	}
}

// fonts
@mixin regular {
	@include first-font-regular;
}
@mixin regular-tablet {
	@include breakpoint (medium down) {
		@include first-font-regular;
	}
}
@mixin regular-mobile {
	@include breakpoint (small down) {
		@include first-font-regular;
	}
}
@mixin medium {
	@include first-font-medium;
}
@mixin medium-tablet {
	@include breakpoint (medium down) {
		@include first-font-medium;
	}
}
@mixin medium-mobile {
	@include breakpoint (small down) {
		@include first-font-medium;
	}
}
@mixin semibold {
	@include first-font-semibold;
}
@mixin semibold-tablet {
	@include breakpoint (medium down) {
		@include first-font-semibold;
	}
}
@mixin semibold-mobile {
	@include breakpoint (small down) {
		@include first-font-semibold;
	}
}
@mixin bold {
	@include first-font-bold;
}
@mixin bold-tablet {
	@include breakpoint (medium down) {
		@include first-font-bold;
	}
}
@mixin bold-mobile {
	@include breakpoint (small down) {
		@include first-font-bold;
	}
}

// first-font
@mixin first-font-regular {
	font-family: $first-font;
	font-weight: 400;
}
@mixin first-font-regular-tablet {
	@include breakpoint (medium down) {
		font-family: $first-font;
		font-weight: 400;
	}
}
@mixin first-font-regular-mobile {
	@include breakpoint (small down) {
		font-family: $first-font;
		font-weight: 400;
	}
}
@mixin first-font-medium {
	font-family: $first-font;
	font-weight: 500;
}
@mixin first-font-medium-tablet {
	@include breakpoint (medium down) {
		font-family: $first-font;
		font-weight: 500;
	}
}
@mixin first-font-medium-mobile {
	@include breakpoint (small down) {
		font-family: $first-font;
		font-weight: 500;
	}
}
@mixin first-font-semibold {
	font-family: $first-font;
	font-weight: 600;
}
@mixin first-font-semibold-tablet {
	@include breakpoint (medium down) {
		font-family: $first-font;
		font-weight: 600;
	}
}
@mixin first-font-semibold-mobile {
	@include breakpoint (small down) {
		font-family: $first-font;
		font-weight: 600;
	}
}
@mixin first-font-bold {
	font-family: $first-font;
	font-weight: 700;
}
@mixin first-font-bold-tablet {
	@include breakpoint (medium down) {
		font-family: $first-font;
		font-weight: 700;
	}
}
@mixin first-font-bold-mobile {
	@include breakpoint (small down) {
		font-family: $first-font;
		font-weight: 700;
	}
}

// flex
@mixin flex {
	display: flex;
}
@mixin flex-tablet {
	@include breakpoint (medium down) {
		display: flex;
	}
}
@mixin flex-mobile {
	@include breakpoint (small down) {
		display: flex;
	}
}
@mixin flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
}
@mixin flex-center-tablet {
	@include breakpoint (medium down) {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
@mixin flex-center-mobile {
	@include breakpoint (small down) {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
@mixin wrap {
	flex-wrap: wrap;
}
@mixin wrap-tablet {
	@include breakpoint (medium down) {
		flex-wrap: wrap;
	}
}
@mixin wrap-mobile {
	@include breakpoint (small down) {
		flex-wrap: wrap;
	}
}
@mixin no-wrap {
	flex-wrap: no-wrap;
}
@mixin no-wrap-tablet {
	@include breakpoint (medium down) {
		flex-wrap: no-wrap;
	}
}
@mixin no-wrap-mobile {
	@include breakpoint (small down) {
		flex-wrap: no-wrap;
	}
}
@mixin justify-start {
	justify-content: flex-start;
}
@mixin justify-start-tablet {
	@include breakpoint (medium down) {
		justify-content: flex-start;
	}
}
@mixin justify-start-mobile {
	@include breakpoint (small down) {
		justify-content: flex-start;
	}
}
@mixin justify-center {
	justify-content: center;
}
@mixin justify-center-tablet {
	@include breakpoint (medium down) {
		justify-content: center;
	}
}
@mixin justify-center-mobile {
	@include breakpoint (small down) {
		justify-content: center;
	}
}
@mixin justify-end {
	justify-content: flex-end;
}
@mixin justify-end-tablet {
	@include breakpoint (medium down) {
		justify-content: flex-end;
	}
}
@mixin justify-end-mobile {
	@include breakpoint (small down) {
		justify-content: flex-end;
	}
}
@mixin justify-space-between {
	justify-content: space-between;
}
@mixin justify-space-between-tablet {
	@include breakpoint (medium down) {
		justify-content: space-between;
	}
}
@mixin justify-space-between-mobile {
	@include breakpoint (small down) {
		justify-content: space-between;
	}
}
@mixin justify-space-around {
	justify-content: space-around;
}
@mixin justify-space-around-tablet {
	@include breakpoint (medium down) {
		justify-content: space-around;
	}
}
@mixin justify-space-around-mobile {
	@include breakpoint (small down) {
		justify-content: space-around;
	}
}
@mixin align-start {
	align-items: flex-start;
}
@mixin align-start-tablet {
	@include breakpoint (medium down) {
		align-items: flex-start;
	}
}
@mixin align-start-mobile {
	@include breakpoint (small down) {
		align-items: flex-start;
	}
}
@mixin align-center {
	align-items: center;
}
@mixin align-center-tablet {
	@include breakpoint (medium down) {
		align-items: center;
	}
}
@mixin align-center-mobile {
	@include breakpoint (small down) {
		align-items: center;
	}
}
@mixin align-middle {
	align-items: center;
	justify-content: center;
}
@mixin align-middle-tablet {
	@include breakpoint (medium down) {
		align-items: center;
		justify-content: center;
	}
}
@mixin align-middle-mobile {
	@include breakpoint (small down) {
		align-items: center;
		justify-content: center;
	}
}
@mixin direction-row {
	flex-direction: row;
}
@mixin direction-row-tablet {
	@include breakpoint (medium down) {
		flex-direction: row;
	}
}
@mixin direction-row-mobile {
	@include breakpoint (small down) {
		flex-direction: row;
	}
}
@mixin direction-column {
	flex-direction: column;
}
@mixin direction-column-tablet {
	@include breakpoint (medium down) {
		flex-direction: column;
	}
}
@mixin direction-column-mobile {
	@include breakpoint (small down) {
		flex-direction: column;
	}
}

//$$ mixins-hook-end $$
// *** DO NOT REMOVE THIS ***