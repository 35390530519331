* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	color: $black;
	list-style-type: none;
	outline: 0 !important;
	letter-spacing: 0.5px;
}

body {
	overflow-x: hidden;
	@include first-font-regular;
}

hr {
	border: 0;
	border-top: 1px solid $line-color;
}

.row {
	max-width: 100rem;

	&.centered {
		max-width: 80rem;
	}
}

a {
	text-decoration: none;
}

.block-scroll {
	overflow-y: hidden;
}

.wrapper {
	min-height: calc(100vh - 200px);
}

.relative {
	position: relative;
}

.absolute {
	position: absolute;
	&.top-100 {
		top: 100%;
	}
	&.top-0 {
		top: 0;
	}
	&.bottom-0 {
		bottom: 0;
	}
	&.right-0 {
		right: 0;
	}
	&.left-0 {
		left: 0;
	}
}

.position-initial {
	position: initial !important;
}

.width-100 {
	width: 100% !important;
}

.max-content {
	width: max-content !important;
}

.width-80 {
	width: 80%;
}

.width-50 {
	width: 50%;
}
.width-fit-content {
	width: fit-content;
}

.height-fit-content {
	height: fit-content;
}

.max-width-50 {
	width: 50% !important;
}

.width-100-tablet {
	@include breakpoint(medium down) {
		width: 100% !important;
	}
}

.width-100-mobile {
	@include breakpoint(small down) {
		width: 100% !important;
	}
}

.height-100 {
	height: 100%;
}

.list-unstyled {
	list-style: none;
}

.block {
	display: block;
}
.inline {
	display: inline;
}
.inline-block {
	display: inline-block;
}

.hide {
	display: none;
}

.sticky {
	position: sticky;
}

.clickable {
	cursor: pointer;
}

.notClickable {
	cursor: default;
}

.sep {
	height: 100%;
	border-right: 1px solid $line-color;
}

.divide {
	border-top: 3px solid $white;
	margin: auto;
	@include mv-xs;
	max-width: 200px;
}

.search-input-fixer {
	border: none !important;
	border-bottom: 1px solid $black !important;
	border-radius: 0 !important;
	box-shadow: none !important;
}

.show-for-small {
	@media screen and (min-width: 640px) {
		display: none;
	}
}
.show-for-medium {
	@media screen and (max-width: 639px) {
		display: none;
	}
}
.show-for-large {
	@media screen and (max-width: 1367px) {
		display: none;
	}
}
.hide-for-small {
	@media screen and (max-width: 639px) {
		display: none;
	}
}
.hide-for-medium {
	@media screen and (min-width: 640px) {
		display: none;
	}
}
.hide-for-large {
	@media screen and (min-width: 1367px) {
		display: none;
	}
}

.dropdown {
	.dropdown-toggle {
		.dropdown-icon {
			transition: transform 0.4s;
			background-repeat: no-repeat;
		}
		
		&.open .dropdown-icon {
			transform: rotate3d(1, 0, 0, 180deg);
			background-repeat: no-repeat;
		}
	}

	.dropdown-content {
		display: none;
	}
}
