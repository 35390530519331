.subscribe-newsletter {
    display: flex;
    flex-direction: column;
    align-items: center;

    .checkbox-container {
        label {
            &,
            * {
                color: $white;
            }
        }
    }
    .email-input {
        width: 100%;
    }

    .captcha-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: $xxs;
    }
}

.popup-container {
    width: 100dvw;
    width: 100vw;
    height: 100dvh;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: $header-z + 100;
}

.popup {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    .popup-image {
        background-color: $white;
        // max-width: 900px;
        // aspect-ratio: 5 / 3;
        margin: $m;
        position: relative;
        @include breakpoint(small down) {
            margin: $xxs;
            * {
                font-size: 14px;
                line-height: 18px;
            }
        }

        .popup-content {
            position: absolute;
            top: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100%;
            text-align: center;
            padding-right: $xl;
        }
    }

    .popup-close {
        cursor: pointer;
        width: 40px;
        height: 40px;
        background-color: $first-color;
        color: $white;
        position: absolute;
        top: 13px;
        right: 13px;
        @include h3;
        @include bold;

        @include breakpoint(small down) {
            @include h4;
            @include bold;
            width: 24px;
            height: 24px;
        }
    }
}

.grecaptcha-badge {
    z-index: 1;
}