#home-container {
	
	.grey-banner {
		background-color: $light-grey;
		min-height: 420px;
	}

	.one-column-banner {
		background-repeat: no-repeat;
		background-position: center right;
		background-size: cover;
		cursor: pointer;

		h2,
		p,
		strong {
			color: $white;
		}
	}

	.two-columns-banner {
		cursor: pointer;
		min-height: 400px;

		.banner-column {
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;

			@include breakpoint(small down) {
				> * {
					background-color: rgba($color: $white, $alpha: .6);
				}
			}
		}
	}

	#main-slider {
		@include breakpoint(xxlarge only) {
			height: 460px;
			.slide-content {
				padding: $ll;
			}
		}
		@include breakpoint(xlarge only) {
			height: 420px;
			.slide-content {
				padding: $l;
			}
		}
		@include breakpoint(large only) {
			height: 400px;
			.slide-content {
				padding: $m;
			}
		}
		@include breakpoint(medium only) {
			height: 380px;
			.slide-content {
				padding: $s;
			}
		}
		@include breakpoint(small only) {
			height: 360px;
			.slide-content {
				padding: $xs;
			}
		}
	}

	#tabbed-notebook-info {
		[data-tab].selected {
			color: $first-color;
			text-decoration: underline;
		}

		.hide-scrollbar {
			overflow-y: hidden;
			height: 48px;

			[data-tabs-container] {
				overflow-x: auto;
				width: 100vw;
				@include ph-s;
				@include breakpoint(large up) {
					display: none;
				}
			}
		}

		.tab {
			@include breakpoint(large up) {
				display: block !important;
			}

			display: flex;
			flex-direction: column;
			@include breakpoint(medium small down) {
				align-items: center;
			}
		}

		.tab-border {
			@include breakpoint(large up) {
				&.top {
					border-top: 1px solid $grey-1;
				}
				&.right {
					border-right: 1px solid $grey-1;
				}
				&.bottom {
					border-bottom: 1px solid $grey-1;
				}
				&.left {
					border-left: 1px solid $grey-1;
				}
			}
		}

		.bg-lightblue {
			background-color: $bg-lightblue;
			margin-top: $xs;
		}

		.txt-lightblue {
			color: $lightblue;
		}
	}

	#info-panel:not(.open) {
		display: none !important;
		* {
			color: $dark-grey;
		}

		.close-button {
			position: absolute;
			top: 16px;
			right: 16px;
		}
	}
}

#info-panel {
	display: block !important;
	* {
		color: $dark-grey;
	}

	.close-button {
		position: absolute;
		top: 16px;
		right: 16px;
	}
}

.circle {
	&.red {
		position: absolute;
	}
	&.purple {
		position: absolute;
		bottom: 50%;
		right: 0;
	}
	&.orange {
		position: absolute;
		right: 0;
		top: 10a0px;
	}
	&.pink {
		position: absolute;
		top: -20px;
	}
	&.blue {
		position: absolute;
		right: 0;
		bottom: -20px;
	}
}

.bg-image-center {
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
}
.bg-image-top {
	background-position: top;
	background-repeat: no-repeat;
	background-size: cover;
}

.news-swiper-slide {
	margin: auto !important;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	min-height: 360px;

	
	@include breakpoint(small down) {
		min-height: 335px;
	}
	@include breakpoint(xxlarge up) {
		width: 90%;
		&.left {
			margin-left: 0 !important;
		}
		&.right {
			margin-right: 0 !important;
		}
		
	}
	// @include breakpoint (xxlarge down) {
	// 	min-height: 50vh;
	// }
}

.be-more-planet-image {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	width: 80%;
	top: 30%;
}

.object-fit-cover {
	object-fit: cover;
}
.object-fit-contain {
	object-fit: contain;
}

.instagram-image {
	aspect-ratio: 1;
	object-fit: cover;
}

.background-image {
	background-position: center center;
	background-repeat: no-repeat
}

.hero-image-logo {
	@include breakpoint (medium down) {
		width: 220px;
	}
}

.hero-image-container {
	@include breakpoint (medium down) {
		background: none !important;
	}
}

.hero-image-mobile {
	height: 250px;
	background-position: center center;
}

.hero-title {
	font-size: 48px;
	line-height: 56px;
	@include mb-xs;

	@include breakpoint (medium down) {
		@include h2;
		@include first-font-bold;
	}

	+ * * {
		color: inherit;
	}
}

.hero-image-slider .swiper-slide {
	height: auto;
	align-self: stretch;

	> a {
		height: 100%;
		@include pb-l;
	}
}