header {
    position: sticky;
    top: 0;
    padding: $xs 0;
    background-color: $white;
    z-index: $header-z;
    transition: background-color .4s;
    border-bottom: 1px solid $grey-1;

    #menu-toggle {
        color: $white;
        background-color: $first-color;
        border-radius: 20px;
        padding: $xxs $xs;
    }

    #mobile-menu-back {
        visibility: hidden;
    }

    #mobile-menu-back, #mobile-menu-close {
        display: flex;
        align-items: center;
        justify-content: center;
        width: $xl;
        height: $l;
        border-radius: 20px;

        &:active {
            background-color: $grey-1;
        }
    }

    #profile-icon-container {
        position: relative;

        .dropdown {
            display: none;
            position: absolute;
            top: 100%;
            // left: -25%;
            width: 100%;

            ul {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                padding: $xxs 0;
                background-color: $white;
                box-shadow: 0px 0px 4px 3px $box-shadow;

                li {
                    padding: $xxxs $xs;
                    width: 100%;
                    
                    a, form, [type=submit] {
                        cursor: pointer;
                        @include body-3;
                        text-align: center;
                        display: inline-block;
                        margin: 0;
                        padding: 0;
                        width: 100%;
                    }
    
                    &:hover {
                        background-color: $light-grey;
                    }
                }
            }
        }

        &:hover {
            .dropdown {
                display: block;
            }
        }
    }

    #mobile-search {
        display: none;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: $overlay-z;
        padding: $xs;
        background-color: rgba($color: $white, $alpha: .9);

    }

    .close-icon {
        position: absolute;
        top: 100px;
        right: 100px;

        @include breakpoint(small down) {
            top: 48px;
            right: 48px;
        }
    }

    .cart-counter {
        @include align-center;
        @include flex;
        @include justify-center;
        position: absolute;
        background-color: $first-color;
        color: $white;
        border-radius: 50%;
        width: 16px;
        height: 16px;
        font-size: 8px;
        top: -5px;
        right: -5px;
    }

    .header-first-column {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        @include breakpoint (medium down){
            justify-content: space-between;
            padding-top: $xs;
            padding-bottom: $xs;
            height: 86px
        }
    }

}

#language-selector {
    &.language-desktop {
        > ul {
            position: absolute;
            top: 100%;
            background: $white;
            box-shadow: 0px 3px 5px 1px $box-shadow-hover;
            width: max-content;
        }

        &:not(:hover) > ul {
            display: none;
        }
    }
}
.flag-image {
    width: 24px;
    height: 24px;
    border-radius: 50px;
}