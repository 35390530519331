@import "node_modules/foundation-sites/scss/foundation";
@import "variables";

$breakpoints: (
  small: 0px,
  medium: 640px + 1px,
  large: 1366px + 1px,
  xlarge: 1400px + 1px,
  xxlarge: 1440px + 1px,
);

$flex: true;

@if $flex {
  $global-flexbox: true !global;
}

@include foundation-global-styles;
@if not $flex {
  @include foundation-grid;
} @else {
  @include foundation-flex-grid;
}
//@include foundation-typography;
@include foundation-forms;
//@include foundation-button;
//@include foundation-accordion;
//@include foundation-accordion-menu;
//@include foundation-badge;
//@include foundation-breadcrumbs;
//@include foundation-button-group;
//@include foundation-callout;
//@include foundation-card;
//@include foundation-close-button;
//@include foundation-menu;
//@include foundation-menu-icon;
//@include foundation-drilldown-menu;
//@include foundation-dropdown;
//@include foundation-dropdown-menu;
//@include foundation-responsive-embed;
//@include foundation-label;
//@include foundation-media-object;
//@include foundation-off-canvas;
//@include foundation-orbit;
@include foundation-pagination;
//@include foundation-progress-bar;
//@include foundation-slider;
//@include foundation-sticky;
//@include foundation-reveal;
//@include foundation-switch;
@include foundation-table;
//@include foundation-tabs;
//@include foundation-thumbnail;
//@include foundation-title-bar;
//@include foundation-tooltip;
//@include foundation-top-bar;
@include foundation-visibility-classes;
//@include foundation-float-classes;

@if $flex {
  @include foundation-flex-classes;
}

@import "spacings";
@import "flex";
@import "mixins";
@import "general";
@import "inputs";
// *** DO NOT REMOVE THIS ***
//$$ imports-hook-start $$
@import "style/typography";
@import "style/colors";
@import "style/backgrounds";
@import "style/overflow";
@import "style/borders";
@import "style/text-alignment";
@import "style/text-decorations";
@import "style/fonts";
@import "style/first-font";
//$$ imports-hook-end $$
// *** DO NOT REMOVE THIS ***
@import "components/breadcrumb";
@import "components/errors";
@import "components/footer";
@import "components/header";
@import "components/menu";
@import "components/newsletter";
@import "components/pagination";
@import "components/product";
@import "components/video";
@import "components/multi-step";
@import "pages/blog";
@import "pages/cart";
@import "pages/wishlist";
@import "pages/catalog";
@import "pages/checkout";
@import "pages/cms";
@import "pages/home";
@import "pages/product";
@import "pages/profile";
@import "pages/subscribe";
@import "pages/contacts";

// Swiper
@import "node_modules/swiper/swiper-bundle";

// Fonts
@import "../fonts/AvantGarde/avantGarde.css";

// intlTelInput
@import "node_modules/intl-tel-input/build/css/intlTelInput";

:root{
	@include first-font-regular;
	font-family: $first-font;
}

.iti {
  width: 100%;
  margin: 0 0 1rem;
}

.iti__flag {
  background-image: url("/skins/current-skin/images/flags/flags.png");
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {
    background-image: url("/skins/current-skin/images/flags/flags@2x.png");
  }
}

// PhotoSwipe
@import "node_modules/photoswipe/dist/photoswipe";

.viewport-height {
  height: calc(100vh - var(--header-height, 0px));
  height: calc(100dvh - var(--header-height, 0px));
}

.fit-cover {
  object-fit: cover;
}

.flex-grow {
  flex-grow: 1;
}