.btn {
    cursor: pointer;
    border: none;
    // height: 48px;
    width: fit-content;
    padding: $xxs $l;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    @include first-font-semibold;
    @include body-1;
    text-transform: uppercase;
    white-space: nowrap;
    @include basic-transition;

    @include breakpoint(medium down) {
        @include body-2;
    }

    span,
    p {
        color: inherit;
        font-size: inherit;
    }

    &.btn-primary {
        color: $white;
        background-color: $first-color;
        height: 48px;

        &:not(:disabled):hover {
            background-color: hover-color($black);
        }
    }

    &.btn-secondary {
        color: $first-color;
        background-color: $white;
        border: 1px solid $first-color;
        height: 48px;

        &:not(:disabled):hover {
            background-color: $light-grey;
        }
    }

    /* btn-primary w/o arrows */
    &.btn-third {
        color: $white;
        background-color: $black;
        height: 48px;

        &:not(:disabled):hover {
            background-color: hover-color($black);
        }
    }

    &.btn-fourth {
        color: $white;
        background-color: $first-color;
        text-transform: initial;
        height: 24px;
        font-size: 13px;
        padding: 16px;

        &:not(:disabled):hover {
            color: hover-color($black);
            border-color: hover-color($black);
        }
    }

    &.btn-next {
        margin-top: $xxs;
    }

    &.btn-no-padding {
        padding: 0 !important;
    }

    &.btn-no-margin {
        margin: 0 !important;
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.3;
    }

    &.btn-padding {
        padding: $xxs $xs;
    }
}

form.loading .btn:disabled, .btn.g-recaptcha:disabled {
	position: relative;

	&::after {
		content: "";
		position: absolute;
		top: calc(50% - 12px);
		left: calc(50% - 12px);
		width: 24px;
		height: 24px;
		border-width: 5px;
		border-style: solid;
		border-radius: 50%;
		display: inline-block;
		box-sizing: border-box;
		animation: rotation 1s linear infinite;
	}

	&.btn-primary {
        color: $first-color;

		&::after {
			border-color: $white;
			border-bottom-color: transparent;
		}
	}
	&.btn-secondary {
        color: $white;

		&::after {
			border-color: $first-color;
			border-bottom-color: transparent;
		}
	}
	&.btn-third {
        color: $black;

		&::after {
			border-color: $white;
			border-bottom-color: transparent;
		}
	}
	&.btn-fourth {
        color: $first-color;

		&::after {
			border-color: $white;
			border-bottom-color: transparent;
		}
	}
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.border-paypal.button {
    border: 1px solid $second-color;
    border-radius: $radius-small;
}

:after {
    box-sizing: border-box;
}

.label-input {
    position: relative;

    label {
        text-align: left;
        font-size: 14px;
        line-height: 24px;
        color: $black;
        @include first-font-semibold;

        span {
            @include first-font-regular;
        }
        a {
            @include first-font-semibold;
        }
    }

    input {
        border: 1px solid $grey-3;
        box-shadow: none;
        @include mb-xs;
        @include pt-xxs;
        @include first-font-regular;

        &:focus {
            border-color: $first-color;
            box-shadow: none;

            + label:not(.error) {
                color: $first-color;
            }
        }
    }

    input::placeholder {
        color: $grey-3;
        opacity: 1;
        @include first-font-regular;
    }

    textarea,
    select {
        border: none;
        box-shadow: none;
        border: 1px solid $grey-3;
        @include mb-xs;
        @include first-font-regular;

        &:focus {
            border-color: $second-color;
            box-shadow: none;
        }
    }

    input,
    select,
    textarea {
        &:disabled,
        &:disabled + label {
            cursor: not-allowed;
            opacity: 0.3;
        }
    }

    label.error {
        &,
        * {
            color: $danger;
        }
    }

    input,
    select,
    textarea {
        &.error:not(:focus) {
            color: $danger;
            border-color: $danger;
        }

        &.error:not(:focus)::placeholder {
            color: rgba($color: $danger, $alpha: 0.7);
        }
    }
}

.checkbox-container {
    display: flex;
    align-items: flex-start;
    position: relative;

    .styled-checkbox {
        margin: 0;
        align-self: center;
    }

    .checkbox {
        opacity: 0;
    }

    .checkbox + label {
        position: relative;
        left: 8px;
        margin: 0;
    }

    .checkbox + label::before {
        content: "";
        position: absolute;
        top: 5px;
        left: -22px;
        width: 16px;
        height: 16px;
        border: 1px solid $grey-3;
    }

    .checkbox:checked + label::after {
        content: "";
        position: absolute;
        top: 7px;
        left: -20px;
        width: 12px;
        height: 12px;
        background-color: $first-color;
    }

    .checkbox.error + label {
        &,
        * {
            color: $danger;
        }

        &::before {
            border-color: $danger;
        }
    }

    .checkbox:disabled + label {
        cursor: not-allowed;
        opacity: 0.3;
    }
}

.email-input {
    width: 50%;
    @include breakpoint(small down) {
        width: 100%;
    }
}

.radio-input {
    @include flex;
    @include direction-row;
    @include wrap;
}

.radio-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: relative;

    @include breakpoint(small down) {
        width: 50%;
    }

    .radio {
        position: relative;
        top: 4px;
        opacity: 0;
    }

    .radio + label::before {
        content: "";
        position: absolute;
        top: 5px;
        left: 0;
        width: 16px;
        height: 16px;
        border: 1px solid $grey-3;
        border-radius: 50%;
    }

    .radio:checked + label::after {
        content: "";
        position: absolute;
        top: 7px;
        left: 2px;
        width: 12px;
        height: 12px;
        background-color: $black;
        border-radius: 50%;
    }

    .radio.error + label {
        &,
        * {
            color: $danger;
        }

        &::before {
            border-color: $danger;
        }
    }

    .radio:disabled + label {
        cursor: not-allowed;
        opacity: 0.3;
    }
}

.search-container {
    position: relative;
    height: fit-content;
    width: 80%;
    border-bottom: 3px solid $first-color;

    .search-input {
        height: 64px;
        width: 100%;
        border: none;
        background-color: transparent;
        box-shadow: none;
        border-radius: 4px;
        padding-left: $s;
        padding-right: $l;
        font-size: 48px;

        @include breakpoint(small down) {
            font-size: 32px;
        }

        &::placeholder {
            color: $grey-3;
            opacity: 1;
        }

        &::-webkit-search-cancel-button {
            -webkit-appearance: none;
        }
    }

    .search-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 64px;
        height: 64px;
        position: absolute;
        top: 0;
        right: 0;
    }

    .search-cancel {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        position: absolute;
        top: 0;
        right: 0;
        visibility: hidden;
    }

    &.has-cancel-btn .search-input:not(:placeholder-shown) {
        ~ .search-btn {
            display: none;
        }
        ~ .search-cancel {
            visibility: visible;
        }
    }
}
