#wishlist-container {
    @include pv-s;

    .cart-details,
    .delivery-details {
        background-color: $white;
    }
    .cart-detail-row {
        margin: $xxs 0;
        display: flex;
        justify-content: space-between;
    }

    .popupBackground {
        background-color: #00000055;
        position: absolute;
        height: 100vh;
        width: 100vw;
        z-index: $overlay-z;
        top: 0;
    }

    .popup {
        background-color: $white;
        padding: $s;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .social {
        &.email {
            background-color: $first-color;
        }
        &.whatsapp {
            background-color: $green-whatsapp;
        }
        &.facebook {
            background-color: $blue-facebook;
        }
        padding: 16px;
        border-radius: 50%;
        width: fit-content;
        height: fit-content;
    }

    .popup-close-icon {
        position: absolute;
        top: 24px;
        right: 24px;
    }

    .txt-ellipsise {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 400px;
        @include breakpoint(small down) {
            max-width: 200px;
        }
    }

    .promo-label-container {
		width: 0;
		height: 0;
		background: none;
		border-width: 100px 0 0 100px;
		border-style: solid;
		position: absolute;
		right: 0;
		top: 0;
		z-index: 10;
		
		.promo-label-text {
			position: absolute;
			top: -71px;
			right: -32px;
			width: 140px;
			text-align: center;
			transform: rotate(45deg);
			@include body-3;
			@include semibold;
		}
	}
}

#shareWishlistPopup:not(.open) {
    display: none;
}