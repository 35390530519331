#btn-next {
  &.open {
    display: none;
  }
}

#submit-container {
  display: none;
  &.open {
    display: block; 
  }
}