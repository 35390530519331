.blog-post-summary-container {
	@include breakpoint(small down) {
		padding-bottom: 20px;
	}

	.blog-post-link {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
	}

	.secondary-post-image {
		height: 250px;
		overflow: hidden;
		@include breakpoint(small down) {
			height: 50vw;
		}

		div {
			background-size: cover;
			background-repeat: no-repeat;
			background-position: top center;
			height: inherit;
			transition: all 0.5s ease-in-out;
		}
	}
	.blog-post-text {
		@include mt-xxs;

		p {
			@include mt-xxs;
		}
		span {
			text-transform: uppercase;
			color: $first-color;
			font-size: 14px;
			@include first-font-semibold;
		}
	}

	// h2 {
	// 	font-size: 18px;
	// 	line-height: 24px;
	// }

	&:hover {
		.blog-post-image div {
			transform: scale(1.1);
		}
	}
}


.blog-post-wrapper {
	padding-top: 20px;

	.blog-post-image {
		background-size: cover;
		background-repeat: no-repeat;
		background-position: top center;

		div {
			background-size: cover;
			background-repeat: no-repeat;
			background-position: top center;
			height: inherit;
			transition: all 0.5s ease-in-out;
		}
	}

	h2, h3 {
		@include mv-xs;
	}

	h4,h5 {
		@include mv-xxs;
	}
	
	p {
		@include mv-xxs;
	}

    a {
        text-decoration: underline;
    }

	ul {
		@include mv-xxs;
		>li {
			list-style-type: disc;
			margin: 0 0 $xxs $xs;
			padding: 0 0 0 $xxxs;
		}
	}
	ol {
		margin: 0 0 $xxs $xs;
		>li {
			list-style-type: decimal;
			margin: 0 0 $xxs $xs;
			padding: 0 0 0 $xxxs;
		}
	}
}

.related-post-title {
	padding: 24px 0;
	border-top: 1px solid $line-color;
	@include mt-l;
}

// archive
.mansory-gallery-blog {
	img {
		height: 440px;
		width: 100%;
		-o-object-fit: cover;
		object-fit: cover;
	}
}

.blog-archive-right-block {
	background-color: #ffffff7a;
	position: absolute;
	left: 40px;
	bottom: 32px;
	width: 80%;
	padding: 16px;
}

.title-blog-archvie {
	font-size: 30px;
	line-height: 40px;
	@include first-font-bold;

	@include breakpoint(medium down) {
		font-size: 25px;
		line-height: 32px;
	}

	&.main-title {
		font-size: 53px;
	}
}
.box-archive-page {
	height: 350px;
}
.box-archive-post {
	height: 440px;
}

// single page blog

.title-article-blog {
	margin: 32px 0px;
	font-size: 40px;
	line-height: 48px;
	@include breakpoint(medium down) {
		font-size: 24px;
		line-height: 32px;
	}
}

.centered-padding-paragraph {
	padding: 0 8vw;
}