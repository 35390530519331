// DESKTOP
.h1, h1 {
	font-size: 70px;
	line-height: 88px;
	@include first-font-bold;
}
.h2, h2 {
	font-size: 35px;
	line-height: 40px;
	@include first-font-regular;
}
.h3, h3 {
	font-size: 25px;
	line-height: 32px;
	@include first-font-regular;
}
.h4, h4 {
	font-size: 18px;
	line-height: 24px;
	@include first-font-regular;
}
.body-1, p {
	font-size: 16px;
	line-height: 24px;
}
.body-2 {
	font-size: 14px;
	line-height: 24px;
}
.body-3 {
	font-size: 12px;
	line-height: 16px;
}

.body-3-all {
	font-size: 12px;
	line-height: 16px;
	*{
		font-size: 12px;
		line-height: 16px;
	}
}

.upper {
	text-transform: uppercase;
}
.lower {
	text-transform: lowercase;
}
.capitalize {
	text-transform: capitalize;
}
.initial {
	text-transform: initial;
}

// TABLET
.h1-tablet {
	@include breakpoint (medium down) {
		font-size: 70px;
		line-height: 88px;
		@include first-font-bold;
	}
}
.h2-tablet {
	@include breakpoint (medium down) {
		font-size: 35px;
		line-height: 40px;
		@include first-font-regular;
	}
}
.h3-tablet {
	@include breakpoint (medium down) {
		font-size: 25px;
		line-height: 32px;
		@include first-font-regular;
	}
}
.h4-tablet {
	@include breakpoint (medium down) {
		font-size: 18px;
		line-height: 24px;
		@include first-font-regular;
	}
}
.body-1-tablet {
	@include breakpoint (medium down) {
		font-size: 16px;
		line-height: 24px;
	}
}
.body-2-tablet {
	@include breakpoint (medium down) {
		font-size: 14px;
		line-height: 24px;
	}
}
.body-3-tablet {
	@include breakpoint (medium down) {
		font-size: 12px;
		line-height: 16px;
	}
}
.upper-tablet {
	@include breakpoint (medium down) {
		text-transform: uppercase;
	}
}
.lower-tablet {
	@include breakpoint (medium down) {
		text-transform: lowercase;
	}
}
.capitalize-tablet {
	@include breakpoint (medium down) {
		text-transform: capitalize;
	}
}
.initial-tablet {
	@include breakpoint (medium down) {
		text-transform: initial;
	}
}

// MOBILE
.h1-mobile {
	@include breakpoint (small down) {
		font-size: 70px;
		line-height: 88px;
		@include first-font-bold;
	}
}
.h2-mobile {
	@include breakpoint (small down) {
		font-size: 35px;
		line-height: 40px;
		@include first-font-regular;
	}
}
.h3-mobile {
	@include breakpoint (small down) {
		font-size: 25px;
		line-height: 32px;
		@include first-font-regular;
	}
}
.h4-mobile {
	@include breakpoint (small down) {
		font-size: 18px;
		line-height: 24px;
		@include first-font-regular;
	}
}
.body-1-mobile {
	@include breakpoint (small down) {
		font-size: 16px;
		line-height: 24px;
	}
}
.body-2-mobile {
	@include breakpoint (small down) {
		font-size: 14px;
		line-height: 24px;
	}
}
.body-3-mobile {
	@include breakpoint (small down) {
		font-size: 12px;
		line-height: 16px;
	}
}
.upper-mobile {
	@include breakpoint (small down) {
		text-transform: uppercase;
	}
}
.lower-mobile {
	@include breakpoint (small down) {
		text-transform: lowercase;
	}
}
.capitalize-mobile {
	@include breakpoint (small down) {
		text-transform: capitalize;
	}
}
.initial-mobile {
	@include breakpoint (small down) {
		text-transform: initial;
	}
}