.video-container {
    height: calc((50vw - 2 / 16 * 50vw) * 9 / 16);
    @include breakpoint ("medium" "down") {
        height: calc((100vw - 2 / 16 * 100vw) * 9 / 16);
    }
    cursor: pointer;
    position: relative;
    background-image: var(--cover-img);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    &:not(.loaded) iframe {
        display: none;
    }

    &.loaded {
        background-image: none;

        .play-icon {
            display: none;
        }
    }

    .play-icon {
        position: absolute;
        left: calc(50% - var(--icon-width) / 2);
        top: calc(50% - var(--icon-height) / 2);
    }
}