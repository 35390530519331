.flex {
    display: flex;


    &.wrap {
        flex-wrap: wrap;
    }
    &.nowrap {
        flex-wrap: nowrap;
    }

    &.wrap-tablet {
        @include breakpoint (medium down) {
            flex-wrap: wrap;
        }
    }
    &.nowrap-tablet {
        @include breakpoint (medium down) {
            flex-wrap: nowrap;
        }
    }

    &.wrap-mobile {
        @include breakpoint (small down) {
            flex-wrap: wrap;
        }
    }
    &.nowrap-mobile {
        @include breakpoint (small down) {
            flex-wrap: nowrap;
        }
    }


    &.justify-start {
        justify-content: flex-start;
    }
    &.justify-end {
        justify-content: flex-end;
    }
    &.justify-center {
        justify-content: center;
    }
    &.justify-space-between {
        justify-content: space-between;
    }
    &.justify-space-around {
        justify-content: space-around;
    }

    &.justify-start-tablet {
        @include breakpoint (medium down) {
            justify-content: flex-start;
        }
    }
    &.justify-end-tablet {
        @include breakpoint (medium down) {
            justify-content: flex-end;
        }
    }
    &.justify-center-tablet {
        @include breakpoint (medium down) {
            justify-content: center;
        }
    }
    &.justify-space-between-tablet {
        @include breakpoint (medium down) {
            justify-content: space-between;
        }
    }
    &.justify-space-around-tablet {
        @include breakpoint (medium down) {
            justify-content: space-around;
        }
    }

    &.justify-start-mobile {
        @include breakpoint (small down) {
            justify-content: flex-start;
        }
    }
    &.justify-end-mobile {
        @include breakpoint (small down) {
            justify-content: flex-end;
        }
    }
    &.justify-center-mobile {
        @include breakpoint (small down) {
            justify-content: center;
        }
    }
    &.justify-space-between-mobile {
        @include breakpoint (small down) {
            justify-content: space-between;
        }
    }
    &.justify-space-around-mobile {
        @include breakpoint (small down) {
            justify-content: space-around;
        }
    }


    &.align-start {
        align-items: flex-start;
    }
    &.align-end {
        align-items: flex-end;
    }
    &.align-center {
        align-items: center;
    }
    &.align-baseline {
        align-items: baseline;
    }
    &.align-self-center {
        align-self: center;        
    }


    &.align-start-tablet {
        @include breakpoint (medium down) {
            align-items: flex-start;
        }
    }
    &.align-end-tablet {
        @include breakpoint (medium down) {
            align-items: flex-end;
        }
    }
    &.align-center-tablet {
        @include breakpoint (medium down) {
            align-items: center;
        }
    }
    &.align-baseline-tablet {
        @include breakpoint (medium down) {
            align-items: baseline;
        }
    }

    &.align-start-mobile {
        @include breakpoint (small down) {
            align-items: flex-start;
        }
    }
    &.align-end-mobile {
        @include breakpoint (small down) {
            align-items: flex-end;
        }
    }
    &.align-center-mobile {
        @include breakpoint (small down) {
            align-items: center;
        }
    }
    &.align-baseline-mobile {
        @include breakpoint (small down) {
            align-items: baseline;
        }
    }
    

    &.direction-row {
        flex-direction: row;
    }
    &.direction-row-reverse {
        flex-direction: row-reverse;
    }

    &.direction-row-tablet {
        @include breakpoint (medium down) {
            flex-direction: row;
        }
    }
    &.direction-row-reverse-tablet {
        @include breakpoint (medium down) {
            flex-direction: row-reverse;
        }
    }

    &.direction-row-mobile {
        @include breakpoint (small down) {
            flex-direction: row;
        }
    }
    &.direction-row-reverse-mobile {
        @include breakpoint (small down) {
            flex-direction: row-reverse;
        }
    }


    &.direction-column {
        flex-direction: column;
    }
    &.direction-column-reverse {
        flex-direction: column-reverse;
    }

    &.direction-column-tablet {
        @include breakpoint (medium down) {
            flex-direction: column;
        }
    }
    &.direction-column-reverse-tablet {
        @include breakpoint (medium down) {
            flex-direction: column-reverse;
        }
    }

    &.direction-column-mobile {
        @include breakpoint (small down) {
            flex-direction: column;
        }
    }
    &.direction-column-reverse-mobile {
        @include breakpoint (small down) {
            flex-direction: column-reverse;
        }
    }
}
.flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
}

.flex-center-tablet {
	@include breakpoint (medium down) {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.flex-center-mobile {
	@include breakpoint (small down) {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}