.alert-box {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  z-index: $messagebox-z;
  * {
    font-weight: bold !important;
  }

  .alert {
    padding: 20px;
    text-transform: uppercase;
    color: $white;
    animation-name: alert;
    animation-duration: 200ms;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
  }

  .alert-danger {
    background-color: $first-color;
  }

  .alert-success {
    background-color: $success  ;
  }
}

@keyframes alert {
  0% {
    transform: translateY(100px);
  }
  100% {
    transform: translateY(0px);
  }
}
