// common paths
$skin: "/skins/bmartigrafiche";
$images: $skin + "/images";
$icons: $images + "/icons";

// *** DO NOT REMOVE THIS ***
//$$ variables-hook-start $$

// colors
$black: #1d1d1b;
$dark-grey: #4d4d4d;
$grey-3: #7c7c7c;
$grey-2: #b3b3b3;
$grey-1: #e0dfe1;
$light-grey: #efefef;
$white: #ffffff;
$first-color: #c22f26;
$second-color: #81408d;
$third-color: #e98729;
$fourth-color: #2e3192;
$fifth-color: #00a4aa;
$line-color: $grey-1;
$danger: #F91E43;
$success: #33a163;
$be-more-planet-green: #228b22;
$orange: #ff9008;
$facebook: #2572cc;
$twitter: #27baf9;
$purple: #81408d;
$blue: #2e3192;
$orange: #e98729;
$teal: #00a4aa;
$pink: #e784a9;
$yellow: #feca2c;
$olive: #b79776;
$red: #d41318;
$bg-lightblue: #c4e2f7;
$lightblue: #009adc;
// borders
$radius-small: 4px;
$radius-medium: 8px;
$radius-large: 16px;

//$$ variables-hook-end $$
// *** DO NOT REMOVE THIS ***

$box-shadow: rgba(0, 73, 183, 0.06);
$box-shadow-hover: rgba(0, 73, 183, 0.15);

@function hover-color($color) {
	@return lighten($color, 10%);
}

// for third party elements
$grey-paypal: #eee;

// share buttons
$blue-facebook: #1877f2;
$blue-google: #4285f4;
$green-whatsapp: #3fc351;

// z-indexes
$messagebox-z: 3000;
$popup: 2500;
$overlay-z: 2000;
$header-z: 1500;
$menu-z: 1000;
$cookie-law-z: 3000;
$under-menu-banner-z: 100;

// fonts
$first-font: "AvantGarde", sans-serif;
