// DESKTOP
.bg-black {
	background-color: $black;
}
.bg-grey-3 {
	background-color: $grey-3;
}
.bg-grey-2 {
	background-color: $grey-2;
}
.bg-grey-1 {
	background-color: $grey-1;
}
.bg-light-grey {
	background-color: $light-grey;
}
.bg-white {
	background-color: $white;
}
.bg-first-color {
	background-color: $first-color;
}
.bg-second-color {
	background-color: $second-color;
}
.bg-third-color {
	background-color: $third-color;
}
.bg-danger {
	background-color: $danger;
}
.bg-success {
	background-color: $success;
}

// TABLET
.bg-black-tablet {
	@include breakpoint (medium down) {
		background-color: $black;
	}
}
.bg-grey-3-tablet {
	@include breakpoint (medium down) {
		background-color: $grey-3;
	}
}
.bg-grey-2-tablet {
	@include breakpoint (medium down) {
		background-color: $grey-2;
	}
}
.bg-grey-1-tablet {
	@include breakpoint (medium down) {
		background-color: $grey-1;
	}
}
.bg-light-grey-tablet {
	@include breakpoint (medium down) {
		background-color: $light-grey;
	}
}
.bg-white-tablet {
	@include breakpoint (medium down) {
		background-color: $white;
	}
}
.bg-first-color-tablet {
	@include breakpoint (medium down) {
		background-color: $first-color;
	}
}
.bg-second-color-tablet {
	@include breakpoint (medium down) {
		background-color: $second-color;
	}
}
.bg-third-color-tablet {
	@include breakpoint (medium down) {
		background-color: $third-color;
	}
}
.bg-danger-tablet {
	@include breakpoint (medium down) {
		background-color: $danger;
	}
}
.bg-success-tablet {
	@include breakpoint (medium down) {
		background-color: $success;
	}
}

// MOBILE
.bg-black-mobile {
	@include breakpoint (small down) {
		background-color: $black;
	}
}
.bg-grey-3-mobile {
	@include breakpoint (small down) {
		background-color: $grey-3;
	}
}
.bg-grey-2-mobile {
	@include breakpoint (small down) {
		background-color: $grey-2;
	}
}
.bg-grey-1-mobile {
	@include breakpoint (small down) {
		background-color: $grey-1;
	}
}
.bg-light-grey-mobile {
	@include breakpoint (small down) {
		background-color: $light-grey;
	}
}
.bg-white-mobile {
	@include breakpoint (small down) {
		background-color: $white;
	}
}
.bg-first-color-mobile {
	@include breakpoint (small down) {
		background-color: $first-color;
	}
}
.bg-second-color-mobile {
	@include breakpoint (small down) {
		background-color: $second-color;
	}
}
.bg-third-color-mobile {
	@include breakpoint (small down) {
		background-color: $third-color;
	}
}
.bg-danger-mobile {
	@include breakpoint (small down) {
		background-color: $danger;
	}
}
.bg-success-mobile {
	@include breakpoint (small down) {
		background-color: $success;
	}
}