#checkout-wrapper {
	@include pt-l;
	@include pb-l;

	h2 {
		@include first-font-bold;
		@include breakpoint(small down) {
			text-align: center;
			margin-bottom: 10px;
		}
	}
}

.cart-detail-row {
	margin: 8px 0;
	display: flex;
	justify-content: space-between;
}

.checkout-summary-title {
	@include h3;
	@include medium;
	@include mb-xs;
	text-transform: capitalize;
}

.checkout-step-title {
	border: 1px solid $grey-1;
	background-color: $white;
	color: $first-color;
	padding: 8px 14px 7px;
	text-transform: uppercase;
	font-weight: 400;
	letter-spacing: 0.05em;
	margin: 12px 0;
	font-size: 14px;
}

.checkout-step-title-next {
	border: 1px solid $grey-1;
	padding: 8px 14px 7px;
	text-transform: uppercase;
	font-weight: 400;
	letter-spacing: 0.05em;
	margin: 12px 0;
	font-size: 14px;
	opacity: 0.3;
}

.checkout-step-title-done {
	border: 1px solid $grey-1;
	padding: 8px 14px 7px;
	text-transform: uppercase;
	font-weight: 400;
	letter-spacing: 0.05em;
	margin: 12px 0;
	font-size: 14px;
}

.cart-summary-container {
	background-color: $light-grey;
	padding: 0;

	.checkout-step-title {
		border-color: $second-color;
	}

	.cart-summary {
		padding: 0 24px 16px;
		font-size: 14px;
	}
}

.product-name {
	max-width: 75%;
}

#billing-form-btn {
	@include breakpoint(small down) {
		margin-bottom: 10px;
	}
}

.checkout-container {
	@include pt-l;
	@include pb-l;

	h1 {
		@include mb-l;
		@include first-font-bold;
	}

	.return-msg {
		@include mt-xs;
		@include mb-xs;
		@include first-font-regular;

		a {
			text-decoration: underline;
			@include first-font-bold;
		}
	}
}

.additional-fee {
	display: block;
	text-align: center;
	text-transform: uppercase;
	font-size: 12px;
	margin: -7px 0 12px;
}

.container-paypal-additional-cost {
	display: flex;
	align-items: center;
	& span {
		font-size: 14px;
		@include first-font-regular;
	}
}

.order-zero-amount {
	@include mb-xxs;
	@include first-font-semibold;
}

.btn.checkout {
	width: 100%;
	padding: 16px;
	margin-bottom: 0;
	@include mt-xxs;
}

#paypalBraintreeButton {
	border: 2px solid $black !important;
	border-radius: 6px !important;
	@include mt-xxs;
}

#billing-form-container {
	display: none;
}
