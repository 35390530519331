#main-menu {
	.main-menu-item {
		@include pv-xxs;

		&:not(:last-child) {
			margin-right: $xxs;
		}

		.submenu-container {
			background-color: $white;
			display: none;
			position: absolute;
			top: 99%;
			left: 0;
			right: -64px;
			width: fit-content;
			box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.3);
		}

		.first-submenu-text-container {
			position: absolute;
			width: 100%;
			height: 45%;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 0 4px;
		}

		.first-submenu-text {
			text-align: center;
			font-size: 14px;

            @media screen and (max-width: 1680px) {
                font-size: 12px;
            }
		}

        .menu-label {
            @include body-2;

            @media screen and (min-width: 1681px) {
                @include body-1;
            }
        }

		&:hover {
			.menu-label {
				color: $first-color;
			}

			.submenu-container {
				display: block;
			}
		}
	}
}

#mobile-menu {
    --header-height: 0;
	display: block;
	position: fixed;
	top: calc(var(--header-height) * 1px);
	bottom:env(safe-area-inset-bottom, 0px);
    left: 0;
	width: 100vw;
    max-height: calc(100vh - var(--header-height) * 1px);
    overflow-y: auto;
	transform: translateX(-100%);
	background-color: transparent;
	transition: transform 0.4s ease-in-out, background-color 0.4s;
    
	&.open {
        background-color: $light-grey;
        transform: translateX(0%);

		.mobile-menu-header {
			opacity: 1;
		}
	}

	.mobile-menu-header {
		opacity: 0;
		transition: opacity 0.4s;

		.button {
			@include first-font-semibold;
			color: $white;
			background-color: $second-color;
			border-radius: 20px;
			padding: $xxs $xs;
		}
	}

	.mobile-menu {
		min-height: calc(100vh - var(--header-height) * 1px);
		padding-bottom: 32px;
		background-color: $white;
		width: 100%;

		.line {
			height: 1px;
			margin: $s $m;
			background-color: $light-grey;
		}

		.mobile-menu-items {
			.mobile-menu-item {
				position: relative;
				display: block;
				width: 100%;
				@include pv-xxs;
				@include ph-m;

				&.menu-flex {
					display: flex;
					justify-content: space-between;
					align-items: center;
					flex-direction: row;
				}

				.pv-8 {
					padding: $xxs $m;
				}

				.icon.arrow {
					position: absolute;
					right: $xxs;
					top: calc(50% - 7px);
					transition: transform 0.2s;
				}

				&:active {
					background-color: $white;
				}

				&:hover {
					background-color: $grey-1;
				}

				&.layer-2 ~ .mobile-submenu {
					border-left: 3px solid $second-color;
				}

				&.open {
					&,
					* {
						font-weight: bold !important;
					}
				}

				&.layer-1.open {
					pointer-events: none;

					&,
					* {
						@include body-1;
					}

					.icon {
						display: none;
					}
				}

				&.layer-2.open {
					.icon.arrow {
						transform: rotate3d(1, 0, 0, 180deg);
					}
				}
			}
		}

		.mobile-submenu {
			display: none;
		}
	}
}


.menu-login-dropdown {
	display: none;
	top: 100%;
	width: 100%;
}

.mobile-language-dropdown {
	top: 100%;
	width: 100%;
	@include ph-m;
	@include mb-xs;
}



.arrow-icon {
	transition: transform 0.2s ease-in-out;
}

.open > .arrow-icon {
    transform: rotateX(180deg);
}

.show-on-mobile-menu-open {
	&:not(.open) {
		display: none !important;
	}
}