#product-container {
	.product-container.product-page {
		border: none;
	}

	.product-description-dropdown {
		// border-top: 1px solid $line-color;

		.dropdown-toggle {
			display: flex;
			align-items: center;
			justify-content: space-between;
			@include pv-s;

			.dropdown-label {
				@include body-1;
				@include first-font-semibold;
			}
		}
	}

	tbody {
		border: none;
	}

	tr {
		border-bottom: 1px solid $grey-2;
		p {
			@include body-3;
		}
	}

	// p {
	// 	@include body-3;
	// }

	tr:nth-child(2n) {
		background-color: transparent;
	}

	.product-thumbnail {
		margin-bottom: $xs;
		opacity: 0.5;
	}

	.product-thumbnail {
		margin-bottom: $xs;
		&.active {
			opacity: 1;
		}
	}

	.description-title {
		@include h4;
		@include first-font-bold;
		margin: $xxs 0;
	}
	.description-text {
		color: $grey-3;
		* {
			@include body-2
		}
	}

	.options-radio {
		.radio {
			display: none;
		}

		&.imgRadio {
			.radio + label {
				border: none;
				img {
					border: 1px solid $grey-2;
					border-radius: 200px;
				}
			}
			.radio:checked + label {
				border: none;
				img {
					border: 1px solid $first-color;
					border-radius: 200px;
				}
			}
			img {
				align-self: center;
			}
		}
		.radio + label {
			border: 1px solid $grey-2;
			margin: $xxs $xs $xxs 0;
			padding: 0 $xxs;
			min-width: 50px;
			text-align: center;
			@include flex;
			@include direction-column;
		}

		.radio:checked + label {
			border: 1px solid $first-color;
			&.imgRadio {
				border: none;
				img {
					align-self: center;
					border: 2px solid $first-color;
					border-radius: 50%;
					padding: 1px;
				}
			}

			color: $first-color;

			span {
				color: $first-color;
			}
		}

		.radio + label::before {
			display: none;
		}

		.radio:checked + label::after {
			display: none;
		}
	}

	.promo-label-container {
		width: 0;
		height: 0;
		background: none;
		border-width: 100px 0 0 100px;
		border-style: solid;
		position: absolute;
		right: 0;
		top: 0;
		z-index: 10;
		
		.promo-label-text {
			position: absolute;
			top: -71px;
			right: -32px;
			width: 140px;
			text-align: center;
			transform: rotate(45deg);
			@include body-3;
			@include semibold;
		}
	}

}

.discounted {
	&, * {
		color: $grey-3;
	}
	text-decoration: line-through;
}

.thumbnail {
	max-height: 500px;
	overflow-y: auto;
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.thumbnail::-webkit-scrollbar {
	display: none;
}

#info-panel:not(.open) {
	display: none !important;
	* {
		color: $dark-grey;
		@include body-1;
	}

	.close-button {
		position: absolute;
		top: 16px;
		right: 16px;
	}
}
#info-panel {
	display: block !important;
	* {
		color: $dark-grey;
		@include body-1;
	}

	.close-button {
		position: absolute;
		top: 16px;
		right: 16px;
	}

	z-index: $header-z - 1;
}

.price-container {
	display: flex;

	&:not(.price-per-piece) {
		.price, .currency {
			@include bold;
		}
	}
}
