.cms-wrapper {
	@include mb-xxl;

	p {
		@include first-font-regular;
		@include mb-xxs;
	}

	ul,
	ol {
		padding: 0 0 16px 24px;
	}

	ol li {
		list-style-type: decimal;
	}

	ul li {
		list-style-type: disc;
	}

	.underline-anchors a {
		text-decoration: underline;
	}
}

.colored-text {
	@include breakpoint(small down) {
		@include body-1;
	}

	&.purple {
		color: $purple;
	}
	&.blue {
		color: $blue;
	}
	&.orange {
		color: $orange;
	}
	&.teal {
		color: $teal;
	}
}

.bg-dim {
	box-shadow: 0 0 0 1000px inset rgba(0, 0, 0, 0.3);
}

.category-banner-image {
	height: 286px;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;

	@include breakpoint(medium down) {
		height: 100px;
	}
}

.category-banner-text {
	* {
		font-size: 14px !important;
	}
}

.tab-selector {
	color: $grey-2;
	&.selected {
		color: $black;
	}
}
.tab-selector-container {
	-ms-overflow-style: none;
	scrollbar-width: none;
}
.tab-selector-container::-webkit-scrollbar {
	display: none;
}

.tab {
	a {
		color: $first-color;
	}
}

.tab-img {
	height: 140px;
}

.curved-line {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
}

.curved-line-mobile {
	position: absolute;
	left: 40px;
}

.history-height {
	height: 300px;
}
#info-panel {
	position: absolute;
	top: 100%;
	left: 0;
}

.info-panel {
	background-color: $grey-1;
	padding: $s;
	z-index: $header-z - 1;
	.close-button {
		position: absolute;
		top: 16px;
		right: 16px;
	}
}

.info-panel-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
}

.colored-title {
	&.orange {
		color: $orange;
	}
	&.teal {
		color: $teal;
	}
	&.purple {
		color: $purple;
	}
	&.pink {
		color: $pink;
	}
	&.blue {
		color: $blue;
	}
	&.yellow {
		color: $yellow;
	}
	&.olive {
		color: $olive;
	}
	&.red {
		color: $red;
	}
}

.vertical-image-height {
	height: 250px;
	object-fit: contain;
}

#tabbed-notebook-info {
	[data-tab].selected {
		color: $first-color;
		text-decoration: underline;
	}

	.hide-scrollbar {
		overflow-y: hidden;
		height: 48px;

		[data-tabs-container] {
			overflow-x: auto;
			width: 100vw;
			@include ph-s;
			@include breakpoint(large up) {
				display: none;
			}
		}
	}

	.tab {
		@include breakpoint(large up) {
			display: block !important;
		}

		display: flex;
		flex-direction: column;
		@include breakpoint(medium small down) {
			align-items: center;
		}
	}

	.tab-border {

		@include breakpoint(large u p) {
			&.top {
				border-top: 1px solid $grey-1;
			}
			&.right {
				border-right: 1px solid $grey-1;
			}
			&.bottom {
				border-bottom: 1px solid $grey-1;
			}
			&.left {
				border-left: 1px solid $grey-1;
			}
		}
	}

	.bg-lightblue {
		background-color: $bg-lightblue;
		margin-top: $xs;
	}

	.txt-lightblue {
		color: $lightblue;
	}
}

.height-50-mobile {
	@include breakpoint (small down) {
		height: 50px;
	}
}

.text-article-blog {
	a {
		text-decoration: underline;
	}
}

.hero-4 {
	p, strong {
		color: inherit;
	}
}

#happ-to-learn-banner {
	@include breakpoint(small down) {
		object-fit: cover;
		height: 52vw;
	}
}