// DESKTOP
.overflow-hidden {
	overflow: hidden;
}
.overflow-auto {
	overflow: auto;
}
.overflow-scroll {
	overflow: scroll;
}

// TABLET
.overflow-hidden-tablet {
	@include breakpoint (medium down) {
		overflow: hidden;
	}
}
.overflow-auto-tablet {
	@include breakpoint (medium down) {
		overflow: auto;
	}
}
.overflow-scroll-tablet {
	@include breakpoint (medium down) {
		overflow: scroll;
	}
}

// MOBILE
.overflow-hidden-mobile {
	@include breakpoint (small down) {
		overflow: hidden;
	}
}
.overflow-auto-mobile {
	@include breakpoint (small down) {
		overflow: auto;
	}
}
.overflow-scroll-mobile {
	@include breakpoint (small down) {
		overflow: scroll;
	}
}