#product-container {
    @include pv-xxs;

    .product-container {
        .product-text {
            .product-title {
                font-size: 25px;
                line-height: 32px;
            }

            .product-subtitle {
                margin-top: $xxs;
            }

            .product-subsubtitle {
                margin-top: $xxs;

                &,
                & span {
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }
    }
}

h4 {
    @include first-font-semibold;
    margin: $xxs 0;
}

.profile-content {
    @include breakpoint(small down) {
        padding-left: 0;
        @include mt-s;
    }

    hr {
        border: none;
        border-top: 1px solid $line-color;
        @include mt-s;
    }
}

.table-orders {
    border-collapse: collapse;

    th {
        text-align: left;
        font-size: 12px;
        text-transform: uppercase;
        font-weight: normal;
        @include pb-xxs;
        @include pl-xxs;
        @include first-font-bold;
    }

    td {
        @include first-font-regular;
        @include body-2;
    }

    p {
        @include body-2;
    }

    tr.order-controls {
        background-color: $white;
    }

    &tbody tr:nth-child(2n) {
        border-bottom: 0;
        background-color: $white;
    }

    & thead {
        border: none;
        &.row-article {
            background-color: $white;
        }
    }

    & tbody {
        border: none;
    }
}

.responsive-table {
    @include mt-xs;
}

.order-row {
    cursor: pointer;
    @include basic-transition;

    &:hover {
        background-color: $light-grey;
    }

    td {
        padding: 8px 8px;
        font-size: 14px;
        border-top: 1px solid $line-color;
        border-bottom: 1px solid $line-color;

        // &:first-child {
        // 	width: 21px;
        // }
    }

    .order-row-indicator {
        @include basic-transition;
        position: relative;
        top: -1px;
        transform: rotate(-90deg);
    }
    
    &.open .order-row-indicator {
        transform: rotate(0deg);
    }
}

.order-controls-row {
    &:not(.open) {
        display: none;
    }

    .columns {
        display: flex;
        align-items: center;
    }
}

.order-controls {
    display: none;
    // border-left: 1px solid $second-color;

    > td {
        padding: 16px 24px;
    }
    &.open {
        display: table-row !important;
    }
}

.order-controls-return {
    border-collapse: collapse;
    border-top: 1px solid $line-color;
    td {
        border-top: 1px solid $line-color;
        padding: 4px 8px;
        line-height: 1.43;
        vertical-align: middle;
    }

    tbody tr {
        background-color: $white;
    }
}

.new-return-request:not(.open) {
    display: none;
}

.table-orders {
    min-width: 650px;
}

.responsive-table {
    overflow-x: auto;
}

// profile consent
.profile-privacy-row {
    h4 {
        color: $black;
        text-transform: uppercase;
        font-size: 14px;
        @include mb-xxs;
    }
}

.inline-checkbox {
    label {
        @include first-font-regular;
    }
}

.profile-privacy-col-inputs {
    display: flex;
    justify-content: space-between;
    @include breakpoint(small down) {
        flex-direction: column;
    }
}

.profile-nav {
    color: $black;
    background: $white;
    font-size: 14px;

    a,
    [type="submit"] {
        cursor: pointer;
        color: $black;
        background: $white;
        border: none;
        transition: all 0.15s ease-in-out;
        display: block;
        text-align: left;
        letter-spacing: 0.1em;
        margin-bottom: 0;
        text-transform: uppercase;
        padding: 16px 8px;
        @include first-font-bold;

        &.active,
        &:hover {
            color: $white;
            background: $black;
        }
    }
}

ul.list-unstyled.profile-nav li.active {
    background-color: $second-color;
    color: $second-color;
    padding: 16px 8px;
}
input:disabled,
input[readonly],
textarea:disabled,
textarea[readonly] {
    background-color: $light-grey;
}

.my-orders {
    padding-bottom: 14px;
}

.image-cell {
    display: flex;
    align-items: center;
    @include breakpoint(small down) {
        align-items: start;
    }
    @include direction-column-mobile;
}

//profile subscription
.subscription-type-title {
    display: flex;
    align-items: center;
}

.profile-credit {
    .title {
        @include mt-xxs;
    }

    .row {
        @include mt-xxs;
    }
}

.tools-export {
    @include mt-l;
    @include mb-l;
}

.tools-export-title {
    @include first-font-bold;
}

.tools-export-description {
    @include first-font-regular;
}

.avaliable-every {
    @include first-font-regular;
}

.return-requests-container {
    h3 {
        @include mb-xs;
        @include first-font-bold;
    }
}
