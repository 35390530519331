#cart-container {
    @include pv-s;

    .cart-details, .delivery-details {
        background-color: $white;
    }
    .cart-detail-row {
        margin: $xxs 0;
        display: flex;
        justify-content: space-between;
    }
    .cart-row-icon {
        height: 18px
    }
    .promo-label-container {
		width: 0;
        height: 0;
        margin: 8px auto;
        background: none;
        border-width: 30px 0 0 30px;
        border-style: solid;
        position: absolute;
        right: 0;
        top: -7px;
        z-index: 10;

		.promo-label-text {
			display: none;
        }
	}
}