// DESKTOP
.regular {
	@include first-font-regular;
}
.medium {
	@include first-font-medium;
}
.semibold {
	@include first-font-semibold;
}
.bold {
	@include first-font-bold;
}

// TABLET
.regular-tablet {
	@include breakpoint (medium down) {
		@include first-font-regular;
	}
}
.medium-tablet {
	@include breakpoint (medium down) {
		@include first-font-medium;
	}
}
.semibold-tablet {
	@include breakpoint (medium down) {
		@include first-font-semibold;
	}
}
.bold-tablet {
	@include breakpoint (medium down) {
		@include first-font-bold;
	}
}

// MOBILE
.regular-mobile {
	@include breakpoint (small down) {
		@include first-font-regular;
	}
}
.medium-mobile {
	@include breakpoint (small down) {
		@include first-font-medium;
	}
}
.semibold-mobile {
	@include breakpoint (small down) {
		@include first-font-semibold;
	}
}
.bold-mobile {
	@include breakpoint (small down) {
		@include first-font-bold;
	}
}