#catalog-container {
	&.loading {
		#catalog-wrapper {
			opacity: 0.5;
		}
	}

	#filters-toggle {
		.icon {
			transition: transform 0.2s;
		}

		&.open .icon {
			transform: rotate(90deg);
		}
	}

	#filters {
		// display: block;
		@include flex;
		@include justify-start;
		@include wrap;

		.filter-tag {
			// flex-grow: 1;
			@include ml-xs-mobile;
			@include mr-xs-mobile;
			position: relative;
			@include breakpoint (medium down) {
				flex-grow: 0;
			}
		}

		.close-mobile {
			position: absolute;
			top: 24px;
			right: 24px;
		}

		@include breakpoint (medium down) {
			flex-direction: column;
			position: fixed;
			top: 0;
			bottom: 0;
			left: 0;
			width: 100vw;
			width: 100dvw;
			background: white;
			z-index: $overlay-z;
			padding: $xs;
			transition: transform 0.5s;
			transform: translateX(-100%);
			overflow: scroll;
			flex-wrap: nowrap;
		}

		.dropdown {
			// &:not(:last-child) {
			// 	margin-bottom: $xxxs;
			// }

			.dropdown-toggle {
				@include flex;
				align-items: center;
				justify-content: space-between;
				height: 40px;
				background-color: $white;
				width: 100%;
				cursor: pointer;
				border-right: 1px solid transparent;
				border-left: 1px solid transparent;
				padding: 0 8px;


				.dropdown-label {
					@include body-1;
				}

				.dropdown-icon {
					transition: transform 0.2s;
					@include mh-xxs
				}
			}

			.dropdown-toggle.open {
				background-color: transparent;
				border-top: 3px solid black;
				@include border-right;
				@include border-left;

				.dropdown-label {
					// @include first-font-semibold;
					-webkit-text-stroke: 0.6px black;
					margin-bottom: 3px;
					@include breakpoint (medium down) {
						margin-bottom: 0;
					}
				}

				.dropdown-icon {
					transform: rotate3d(1, 0, 0, 180deg);
				}

				@include breakpoint (medium down) {
					@include border-no-top;
					@include border-no-right;
					@include border-no-left;
				}
			}

			.dropdown-content {
				max-height: 360px;
				width: max-content;
				overflow-y: auto;
				position: absolute;
				top: calc(100% + 1px);
				z-index: $menu-z;
				box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.2);
				background-color: $white;
				
				ul {
					@include pv-xxs;
					@include pr-xs;
					@include pl-xxs;
				}
				
				@include breakpoint (medium down) {
					box-shadow: none;
					position: relative;
					top: 0;
					width: 100%;
				}

				.filters-list {
					overflow: hidden;
					width: 100%;

					.filter {
						@include pl-xs;
						@include mv-xxxs;

						> a {
							display: flex;
							position: relative;
							left: 8px;
							margin: 0;

							&::before {
								content: "";
								position: absolute;
								top: 5px;
								left: -22px;
								width: 16px;
								height: 16px;
								border: 1px solid $grey-3;
							}

							&.checked::after {
								content: "";
								position: absolute;
								top: 7px;
								left: -20px;
								width: 12px;
								height: 12px;
								background-color: $first-color;
							}
						}
					}
				}
			}
		}
	}

	#filters.open {
		@include breakpoint (medium down) {
			transform: translateX(0%);
		}
	}

	.current-filter {
		display: flex;
		align-items: baseline;
		@include body-2;
		padding: 0 $xxs;
		color: $first-color;
		margin-right: $xxs;

		&:nth-last-child(2) {
			margin-right: $xs;
		}

		.icon {
			margin-right: $xs;
		}

		&.remove-all {
			border: none;
			background-color: transparent;
			text-decoration: underline;
			margin-right: 0;
			padding: 0;
		}
	}

	#sort-options {
		position: relative;
		height: 40px;

		.sort-btn {
			cursor: pointer;
			position: relative;
			border-bottom: none;
			transition: border-color 0s linear 0.4s;
			padding: 0 $xs;

			&.open {
				height: 100%;
				@include flex;
				@include justify-center;
				@include align-center;
				transition: border-color 0s linear 0s;
				padding: 0 $xs;

				.icon {
					transform: rotate3d(1, 0, 0, 180deg);
				}

				&::after {
					opacity: 1;
					transition: opacity 0s linear 0s;
				}
			}
		}

		.sort-dropdown {
			display: none;
			position: absolute;
			top: 100%;
			right: 0;
			min-width: 100%;
			z-index: $menu-z;
			background-color: $white;
			padding: $xxs $xs;
			box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.2);
			top: calc(100% + 1px);

			@include breakpoint (medium down) {
				box-shadow: none;
			}

			li {
				padding: $xxxs 0;

				a {
					display: inline-block;
					width: 100%;
					white-space: nowrap;
				}

				&:hover,
				&:active {
					*{
						color: $first-color;
					}
				}
			}
		}
	}
}
