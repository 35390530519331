// .product-container.expanded {
// 	.product-prices {
// 	}
// }

.percentage-discount {
	// border-bottom: 2px solid $first-color;
	position: absolute;
	top: 16px;
	left: 16px;
	background: $first-color;
	padding: $xxxs $xxs;
	color: $white;
	z-index: $under-menu-banner-z;
}
.percentage {
	color: $white
}
.table-sizes {
	margin: 0;

	&,
	thead,
	tbody {
		border: none;
	}

	thead tr th,
	tbody tr td {
		padding: $xs;
		background-color: $white;
		text-align: center;
		text-transform: uppercase;
		white-space: nowrap;

		@include breakpoint(medium down) {
			padding: $xxs;
		}
	}

	thead tr th {
		@include body-3;
		@include first-font-regular;

		@include breakpoint(medium down) {
			max-width: $xxxl;
			overflow-x: hidden;
			text-overflow: ellipsis;
		}
	}

	tbody tr td {
		@include breakpoint(medium down) {
			@include body-3;
		}
	}

	thead tr,
	tbody tr {
		border-bottom: 1px solid $line-color;
	}

	tbody tr td:nth-child(4) {
		color: $grey-3;
	}

	tbody tr td .quantity-input-container {
		margin-left: auto;
		margin-right: auto;
	}
}

.quantity-input-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border: 1px solid $grey-2;
	width: max-content;
	height: 48px;
	padding: $xxxs 0;

	.btn-plus {
		padding-right: $xs;
	}
	.btn-minus {
		padding-left: $xs;
	}
	
	.btn-plus,
	.btn-minus {
		display: flex;
		align-items: center;
		height: 100%;
		cursor: pointer;

		@include breakpoint(medium down) {
			padding: $xxxs;

			&:active {
				background-color: $grey-1;
			}
		}
	}

	.quantity-input {
		padding: 0 $xxs;
		margin: 0;
		width: 42px;
		height: min-content;
		border: none;
		box-shadow: none;
		@include body-2;
		text-align: center;

		&::placeholder {
			color: $grey-1;
			@include body-2;
		}
	}

	@include breakpoint(medium down) {
		padding: 0;
	}
}

.cart-input .quantity-input-container {
	margin: 0;

	.quantity-input {
		margin-right: $xxs;
	}

	.btn-cart-remove,
	.btn-cart-update {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 32px;
		height: 32px;
	}
}

.product-container .product-subtitle {
	height: $xs * 3;
	
	@include breakpoint(small down) {
		height: $xs * 4;
	}
}

.product-cta {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	bottom: $xxs;
	right: $xxs;
	border-radius: 50%;
	background-color: $first-color;
	width: $l;
	height: $l;

	@include breakpoint(small down) {
		display: none;
	}
}

.product-container:not(.expanded):not(.microbox) {
	background-color: $white;
	padding: $xxs 0;
	border: 1px solid $line-color;
	height: 100%;

	.product-img {
		picture + picture,
		img + img {
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			opacity: 0;
			z-index: 1;
		}

		&:not(.ios-img){
			&:hover,
			&:active {
				picture + picture,
				img + img {
					opacity: 1;
				}
			}
		}
	}

	.add-to-wishlist, .remove-from-wishlist {
		position: absolute;
		right: 8px;

		&.wishlist-cross {
			bottom: 0;
			top: 0;
		}
		z-index: $under-menu-banner-z;
		cursor: pointer;
	}
	.not-logged-wishlist {
		position: absolute;
		bottom: 16px;
		right: 16px;
		z-index: $under-menu-banner-z;
		cursor: pointer;
	}
	#single-product-wishlist {
		.add-to-wishlist, .remove-from-wishlist {
			position: absolute;
			bottom: 0;
			right: 0;
			z-index: $under-menu-banner-z;
			cursor: pointer;
		}
		.not-logged-wishlist {
			position: absolute;
			bottom: 0;
			right: 0;
			z-index: $under-menu-banner-z;
			cursor: pointer;
		}
	}

	.promo-label-container {
		width: 0;
		height: 0;
		background: none;
		border-width: 100px 0 0 100px;
		border-style: solid;
		position: absolute;
		right: 0;
		top: 0;
		z-index: 10;

		.promo-label-text {
			position: absolute;
			top: -71px;
			right: -32px;
			width: 140px;
			text-align: center;
			transform: rotate(45deg);
			@include body-3;
			@include semibold;
		}
	}
}

.wishlist-button {
	cursor: pointer;
}

.tags-container {
	position: absolute;
	left: 16px;
	bottom: 16px;
	z-index: 100;
	display: flex;
}

.colored-tag {
	color: white;
	font-weight: 600;
	padding: 0px 8px;
	font-size: 14px;
	margin-right: 8px;
	text-transform: capitalize;
	&.green{
		background-color: $be-more-planet-green
	}
	&.new{
		background-color: $first-color;
	}
	&.outlet{
		background-color: $orange;
	}
}

.swiper-pagination-bullet-active {
	background: $black !important;
}